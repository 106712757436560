import { gql } from '@apollo/client';
import { co2AssetPerformanceSharingInfosFragment } from 'modules/sharing/models/AssetSharingInfos/gql.fragment';
import type { Co2AssetSharingInfosFragment } from 'modules/sharing/models/AssetSharingInfos/gql.generated';
import type { Co2Weight } from './types';

export type Co2EmissionType = {
  date: string;
  weight: {
    value: number;
    unit: string;
  };
  fuelConsumption: number;
  usageHours: number;
  assetsCount: number;
  activeAssetsCount: number;
};

export type Co2ReportEmissionsQueryResponse = {
  viewer: {
    id: string;
    co2Report: {
      emissions: Co2EmissionType[];
    };
  };
};

export const co2ReportEmissionsQuery = gql`
  query Co2EmissionsQuery(
    $assetFilters: ReportAssetFilter!
    $periodScale: PeriodScale!
    $since: String!
    $until: String!
  ) {
    viewer {
      id
      co2Report {
        emissions(
          assetFilters: $assetFilters
          periodScale: $periodScale
          period: { since: $since, until: $until }
        ) {
          date
          weight {
            value
            unit
          }
          fuelConsumption
          usageHours
          assetsCount
          activeAssetsCount
        }
      }
    }
  }
`;

type Co2EmissionPeriodData = {
  co2Weight: Co2Weight;
  assetsCount: number;
  activeAssetsCount: number;
  dateStart: string;
  dateEnd: string;
};

export type Co2EmissionsComparativePeriodData = {
  current: Co2EmissionPeriodData | null;
  previous: Co2EmissionPeriodData | null;
};

export type Co2EmissionsReportOverviewByPeriodResponse = {
  viewer: {
    co2Report: {
      emissionCurrentYear: Co2EmissionsComparativePeriodData;
      emissionCurrentMonth: Co2EmissionsComparativePeriodData;
      emissionCurrentWeek: Co2EmissionsComparativePeriodData;
    };
  };
};

export const co2EmissionsReportOverviewByPeriodQuery = gql`
  fragment Co2EmissionComparisonFragment on Co2EmissionOnPeriodItemPeriodComparison {
    current {
      co2Weight {
        value
        unit
      }
      assetsCount
      activeAssetsCount
      dateStart
      dateEnd
    }
    previous {
      co2Weight {
        value
        unit
      }
      assetsCount
      activeAssetsCount
      dateStart
      dateEnd
    }
  }
  query co2EmissionsReportOverviewByPeriodQuery(
    $assetFilters: ReportAssetFilter!
  ) {
    viewer {
      id
      co2Report {
        emissionCurrentYear(assetFilters: $assetFilters) {
          ...Co2EmissionComparisonFragment
        }
        emissionCurrentMonth(assetFilters: $assetFilters) {
          ...Co2EmissionComparisonFragment
        }
        emissionCurrentWeek(assetFilters: $assetFilters) {
          ...Co2EmissionComparisonFragment
        }
      }
    }
  }
`;

export type Co2EmissionsHistoryItem = {
  co2Weight: Co2Weight;
  assetsCount: number;
  activeAssetsCount: number;
  date: string;
};

export type Co2EmissionsHistory = {
  viewer: {
    co2Report: {
      emissionHistory: Co2EmissionsHistoryItem[];
    };
  };
};

export const co2EmissionsHistoryQuery = gql`
  query co2EmissionsHistoryQuery(
    $assetFilters: ReportAssetFilter!
    $periodScale: PeriodScale!
  ) {
    viewer {
      id
      co2Report {
        emissionHistory(
          assetFilters: $assetFilters
          periodScale: $periodScale
        ) {
          co2Weight {
            value
            unit
          }
          date
          assetsCount
          activeAssetsCount
        }
      }
    }
  }
`;

type Co2EmissionStatisticsForPeriod = {
  co2Weight: Co2Weight;
  co2WeightPer100Km: Co2Weight | null;
  co2WeightPerHour: Co2Weight | null;
  assetsCount: number;
  activeAssetsCount: number;
  period: {
    start: string;
    end: string;
  };
};

export type Co2EmissionStatistics = {
  viewer: {
    co2Report: {
      co2EmissionStatistics: {
        current: Co2EmissionStatisticsForPeriod | null;
        previous: Co2EmissionStatisticsForPeriod | null;
      };
    };
  };
};

export const co2EmissionStatisticsQuery = gql`
  fragment Co2EmissionStatisticsComparisonFragment on Co2EmissionStatistics {
    co2Weight {
      value
      unit
    }
    co2WeightPer100Km {
      value
      unit
    }
    co2WeightPerHour {
      value
      unit
    }
    assetsCount
    activeAssetsCount
    period {
      start
      end
    }
  }
  query Co2EmissionStatisticsReport(
    $assetFilters: ReportAssetFilter!
    $period: Period!
  ) {
    viewer {
      id
      co2Report {
        co2EmissionStatistics(assetFilters: $assetFilters, period: $period) {
          current {
            ...Co2EmissionStatisticsComparisonFragment
          }
          previous {
            ...Co2EmissionStatisticsComparisonFragment
          }
        }
      }
    }
  }
`;

export type Co2EmissionForCategory = {
  categoryId: number | null;
  weight: Co2Weight;
  assetsCount: number;
  activeAssetsCount: number;
};

export type Category = {
  id: number;
  name: string;
};

export type Co2EmissionByCategoryResponse = {
  viewer: {
    organization: {
      categories: Category[];
    };
    co2Report: {
      emissionByCategory: Co2EmissionForCategory[];
    };
  };
};

export const co2EmissionByCategoryQuery = gql`
  query Co2EmissionReport($assetFilters: ReportAssetFilter!, $period: Period!) {
    viewer {
      id
      organization {
        id
        categories {
          id
          name
        }
      }
      co2Report {
        emissionByCategory(assetFilters: $assetFilters, period: $period) {
          categoryId
          weight {
            value
            unit
          }
          assetsCount
          activeAssetsCount
        }
      }
    }
  }
`;

export type Co2EmissionAssetsPerformanceRow = {
  assetInOrganizationId: number;
  assetId: number;
  name: string | null;
  make: string | null;
  categoryId: number | null;
  enterprise: string | null;
  model: string | null;
  totalCo2Weight: Co2Weight | null;
  co2PerUsageHour: Co2Weight | null;
  co2Per100km: Co2Weight | null;
  totalUsageHours: number | null;
} & Co2AssetSharingInfosFragment;

export type Co2EmissionAssetsPerformanceResponse = {
  viewer: {
    co2Report: {
      assetsPerformance: {
        rows: Co2EmissionAssetsPerformanceRow[];
        total: number;
        offset: number;
        limit: number;
      };
    };
  };
};

export const co2EmissionAssetsPerformanceQuery = gql`
  ${co2AssetPerformanceSharingInfosFragment}
  query Co2AssetsPerformanceReport(
    $assetFilters: ReportAssetFilter!
    $period: Period!
    $pagination: Pagination!
    $sortBy: Co2AssetsPerformanceSortBy!
  ) {
    viewer {
      id
      co2Report {
        assetsPerformance(
          assetFilters: $assetFilters
          period: $period
          pagination: $pagination
          sortBy: $sortBy
        ) {
          rows {
            assetInOrganizationId
            assetId
            name
            make
            categoryId
            enterprise
            model
            totalCo2Weight {
              value
              unit
            }
            co2PerUsageHour {
              value
              unit
            }
            co2Per100km {
              value
              unit
            }
            totalUsageHours
            ...Co2AssetSharingInfos
          }
          total
          offset
          limit
        }
      }
    }
  }
`;

export type Co2EmissionAssetsListItem = {
  assetInOrganizationId: number;
  assetId: number;
  name: string | null;
  make: string | null;
  totalCo2Weight: Co2Weight | null;
};

export type Co2EmissionAssetsListResponse = {
  viewer: {
    co2Report: {
      assetList: Co2EmissionAssetsListItem[];
    };
  };
};

export type AssetListDataSource = 'daily' | 'monthly';

export const usageHoursAssetsListQuery = gql`
  query Co2AssetsList(
    $assetFilters: ReportAssetFilter!
    $period: Period!
    $dataSource: AssetListDataSource!
  ) {
    viewer {
      id
      co2Report {
        assetList(
          assetFilters: $assetFilters
          period: $period
          dataSource: $dataSource
        ) {
          assetInOrganizationId
          assetId
          name
          make
          totalCo2Weight {
            value
            unit
          }
        }
      }
    }
  }
`;
