import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { SimpleValueWithInfosCard } from 'modules/analytics/components/Cards/SimpleValueCard/SimpleValueCardWithInfos';
import { AssetListDrawer } from 'modules/analytics/components/common/AssetListDrawer';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  type PeriodScale,
  formatDateWithPeriodScale,
  getEndPeriodDate,
} from 'utils/time';
import type { Unit } from 'utils/unit';
import { AssetsListTable } from '../../../components/AssetsListTable';
import { useAssetListExport } from '../../../lib/exportAssetListHook';

type Co2EvolutionDrawerProps = {
  selectedPeriod: { since: Date; until: Date };
  granularityScale: PeriodScale;
  open: boolean;
  assetFilters: AssetFilterGQL;
  onClose: () => void;
  weight: {
    unit: string;
    value: number;
  };
  assetsCount: number;
  activeAssetsCount: number;
};

export const Co2EvolutionDrawer = ({
  selectedPeriod,
  granularityScale,
  open,
  assetFilters,
  onClose,
  weight,
  assetsCount,
  activeAssetsCount,
}: Co2EvolutionDrawerProps) => {
  const { t } = useTranslation('analytics');

  const drawerHeader = () => {
    return (
      <SimpleValueWithInfosCard
        title={formatDateWithPeriodScale(
          new Date(selectedPeriod.since),
          granularityScale,
        )}
        infos={t('reports.common.assetsCountOverTotal', {
          count: assetsCount,
          total: activeAssetsCount,
        })}
        unit={weight.unit as Unit}
        value={weight.value}
      />
    );
  };

  const assetListPeriod = useMemo(() => {
    const startDate = new Date(selectedPeriod.since);
    return {
      start: startDate,
      end: getEndPeriodDate(startDate, granularityScale),
    };
  }, [granularityScale, selectedPeriod]);

  const { triggerAssetListExport, exportLoading } = useAssetListExport();
  const handleAssetListExport = useCallback(() => {
    if (!assetListPeriod) return;
    triggerAssetListExport(
      assetFilters,
      assetListPeriod.start,
      assetListPeriod.end,
      'daily',
    );
  }, [assetFilters, assetListPeriod, triggerAssetListExport]);

  const mainDrawerContent = () => {
    if (!assetListPeriod) return null;
    return (
      <AssetsListTable
        assetFilters={assetFilters}
        assetListDataSource="daily"
        since={assetListPeriod.start}
        until={assetListPeriod.end}
        onExportClick={handleAssetListExport}
        exportLoading={exportLoading}
      />
    );
  };

  return (
    <AssetListDrawer
      open={!!open}
      title={t('reports.carbonEmissions.analysis.evolution.assetDrawerTitle')}
      header={drawerHeader()}
      mainContent={mainDrawerContent()}
      onClose={onClose}
      trackingProps={{
        page: 'carbonEmissions',
        section: 'analysis',
        source: 'evolutionChart',
      }}
    />
  );
};
