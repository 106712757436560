import { useQuery } from '@apollo/client';
import type { TFunction } from 'i18next';
import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import type { LineChartData } from 'modules/analytics/components/Dataviz/LineChart/LineChart';
import { fillEmptyData } from 'modules/analytics/lib/fillEmptyData';
import {
  type Co2EmissionType,
  type Co2ReportEmissionsQueryResponse,
  co2ReportEmissionsQuery,
} from 'modules/analytics/reports/carbonEmissions/gql';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { type Unit, displayValueAndUnit } from 'utils/unit';

const parseChartData = (
  period: { since: Date; until: Date },
  gqlData: Co2EmissionType[] | undefined,
  t: TFunction<'analytics', undefined>,
): LineChartData[] => {
  const res: LineChartData[] = [];

  if (!gqlData || gqlData.length === 0) {
    return [];
  }
  for (const item of gqlData) {
    const tooltipData = [
      {
        label: t('reports.carbonEmissions.overview.chart.tooltip.co2Emissions'),
        value: `<b>${displayValueAndUnit(
          item.weight.value,
          (item.weight.unit as Unit) || 't',
          t,
        )}</b>`,
      },
      {
        label: t(
          'reports.carbonEmissions.overview.chart.tooltip.fuelConsumption',
        ),
        value: `<b>${displayValueAndUnit(item.fuelConsumption, 'L', t)}</b>`,
      },
      {
        label: t('reports.carbonEmissions.overview.chart.tooltip.usedAssets'),
        value: `<b>${item.assetsCount}</b>/${item.activeAssetsCount}`,
      },
    ];
    const date = new Date(item.date);
    res.push({
      value: item.weight.value,
      date,
      originalData: item,
      tooltipData: [tooltipData[0], tooltipData[2]],
    });
  }

  return fillEmptyData(res, period, 'day');
};

export const useGetCarbonEmissionsData = ({
  assetFilters,
  since,
  until,
}: {
  assetFilters: AssetFilterGQL;
  since: Date;
  until: Date;
}) => {
  const { t } = useTranslation('analytics');

  const { loading, data } = useQuery<Co2ReportEmissionsQueryResponse>(
    co2ReportEmissionsQuery,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        assetFilters,
        since: since,
        until: until,
        periodScale: 'DAY',
      },
    },
  );

  const parsedData = useMemo(
    () =>
      parseChartData({ since, until }, data?.viewer?.co2Report.emissions, t),
    [data, since, until, t],
  );

  return {
    data: parsedData,
    loading,
  };
};
