import { BREAKPOINT_MOBILE_END } from 'constants/breakpoints';
import colors from 'constants/colors';
import {
  LineChart,
  type LineChartData,
} from 'modules/analytics/components/Dataviz/LineChart/LineChart';
import { SimpleValue } from 'modules/analytics/components/Dataviz/SimpleValue/SimpleValue';
import { Card } from 'modules/analytics/components/common/layout/Card';
import type { Co2EmissionType } from 'modules/analytics/reports/carbonEmissions/gql';
import type { useCo2EmissionStatistics } from 'modules/analytics/reports/carbonEmissions/sections/CarbonEmissionsAnalysis/cards/Co2Statistics';
import { Button } from 'modules/common-ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import type { PeriodScale } from 'utils/time';

const CardContainer = styled.div`
  flex: 1 1 calc(50% - 16px); /* Two items per row with gap adjustment */
  max-width: calc(50% - 16px);

  @media (max-width: ${BREAKPOINT_MOBILE_END}px) {
    flex: 1 1 100%; /* Full width for smaller screens */
    max-width: 100%;
  }
`;

const AssetsCount = styled.div`
  color: ${colors.gray500};
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 2px;
`;

type CarbonEmissionsComponentProps = {
  statisticData: ReturnType<Awaited<typeof useCo2EmissionStatistics>>['data'];
  data: LineChartData[];
  setSelectedDataPoint: (period: Co2EmissionType | null) => void;
  setGranularityScale: (scale: PeriodScale) => void;
};

export const CarbonEmissionsComponent = ({
  statisticData,
  data,
  setSelectedDataPoint,
  setGranularityScale,
}: CarbonEmissionsComponentProps) => {
  const { t } = useTranslation('newHome');
  const navigate = useNavigate();

  return (
    <>
      <CardContainer>
        <Card
          title={t('analytics.kpi.carbonEmissions.title')}
          titleInfo={t('analytics.kpi.carbonEmissions.help')}
          actionElement={
            <>
              <Button
                onClick={() => {
                  setGranularityScale('week');
                  setSelectedDataPoint(
                    statisticData
                      ? {
                          // not relevant
                          date: new Date().toString(),
                          weight: {
                            value: statisticData.co2Weight?.current?.value || 0,
                            unit: statisticData.co2Weight?.current?.unit || 't',
                          },
                          activeAssetsCount:
                            statisticData.currentActiveAssetsCount || 0,
                          assetsCount: statisticData.currentAssetsCount || 0,
                          // not relevant
                          fuelConsumption: 0,
                          // not relevant
                          usageHours: 0,
                        }
                      : null,
                  );
                }}
              >
                {t('analytics.kpi.common.viewAssets')}
              </Button>
              <Button onClick={() => navigate('/analytics/carbon-emissions')}>
                {t('analytics.kpi.common.analyze')}
              </Button>
            </>
          }
          disableImageExport
        >
          <SimpleValue
            value={statisticData?.co2Weight?.current?.value || null}
            unit={statisticData?.co2Weight?.current?.unit || 't'}
            comparativeValue={statisticData?.co2Weight?.previous?.value || null}
            orientation="row"
          />
          <AssetsCount>
            {t('analytics.kpi.common.assetsCount', {
              assetsCount: statisticData?.currentActiveAssetsCount,
            })}
          </AssetsCount>
          <LineChart
            data={data}
            unit={'t'}
            tooltipV2
            periodScale="day"
            displayBars
            onItemClicked={(item: Co2EmissionType) => {
              setGranularityScale('day');
              setSelectedDataPoint(item);
            }}
          />
        </Card>
      </CardContainer>
    </>
  );
};
