import { useQuery } from '@apollo/client';
import { SimpleValueCard } from 'modules/analytics/components/Cards/SimpleValueCard';
import { SimpleValueWithInfosCard } from 'modules/analytics/components/Cards/SimpleValueCard/SimpleValueCardWithInfos';
import { AssetListDrawer } from 'modules/analytics/components/common/AssetListDrawer';
import { TooltipContent } from 'modules/analytics/components/common/Tooltip/Tooltip';
import { Bold } from 'modules/common-ui';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  formatDateShort,
  getPeriodLabel,
  getUTCEndOfDayDate,
  getUTCStartOfDayDate,
} from 'utils/time';
import type { Unit } from 'utils/unit';
import { AssetsListTable } from '../../../components/AssetsListTable';
import {
  type Co2EmissionStatistics,
  co2EmissionStatisticsQuery,
} from '../../../gql';
import { useAssetListExport } from '../../../lib/exportAssetListHook';
import type { Co2EmissionsAnalysisCardProps } from './types';

const Co2EmissionStatisticsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 4px;
`;

type StatisticCardProps = {
  loading: boolean;
  currentValue: number | null;
  previousValue: number | null;
  currentAssetsCount?: number;
  currentActiveAssetsCount?: number;
  previousAssetsCount?: number;
  previousActiveAssetsCount?: number;
  title: string;
  unit: Unit;
  currentPeriodLabel: string;
  previousPeriodLabel: string;
  onClick?: () => void;
};

const Co2WeightStatisticCard = ({
  loading,
  currentValue,
  previousValue,
  currentAssetsCount,
  currentActiveAssetsCount,
  previousAssetsCount,
  previousActiveAssetsCount,
  title,
  unit,
  currentPeriodLabel,
  previousPeriodLabel,
  onClick,
}: StatisticCardProps) => {
  return (
    <SimpleValueCard
      loading={loading}
      title={title}
      value={currentValue}
      comparativeValue={previousValue}
      unit={unit}
      tooltip={
        <TooltipContent
          label={title}
          currentPeriodLabel={currentPeriodLabel}
          previousPeriodLabel={previousPeriodLabel}
          currentValue={currentValue}
          currentValueDetails={
            currentAssetsCount && currentActiveAssetsCount ? (
              <>
                <Bold>{currentAssetsCount}</Bold>/{currentActiveAssetsCount}
              </>
            ) : undefined
          }
          previousValue={previousValue}
          previousValueDetails={
            previousAssetsCount && previousActiveAssetsCount ? (
              <>
                <Bold>{previousAssetsCount}</Bold>/{previousActiveAssetsCount}
              </>
            ) : undefined
          }
          unit={unit}
        />
      }
      onClick={onClick}
    />
  );
};

export const useCo2EmissionStatistics = ({
  assetFilters,
  since,
  until,
}: Co2EmissionsAnalysisCardProps) => {
  const parseGQLResponse = (
    data: Co2EmissionStatistics['viewer']['co2Report']['co2EmissionStatistics'],
  ) => {
    return {
      co2Weight: {
        current: data.current?.co2Weight,
        previous: data.previous?.co2Weight,
      },
      co2WeightPer100Km: {
        current: data.current?.co2WeightPer100Km,
        previous: data.previous?.co2WeightPer100Km,
      },
      co2WeightPerHour: {
        current: data.current?.co2WeightPerHour,
        previous: data.previous?.co2WeightPerHour,
      },
      currentAssetsCount: data.current?.assetsCount,
      currentActiveAssetsCount: data.current?.activeAssetsCount,
      previousAssetsCount: data.previous?.assetsCount,
      previousActiveAssetsCount: data.previous?.activeAssetsCount,
      currentPeriod: data.current?.period,
    };
  };

  const { loading, data } = useQuery<Co2EmissionStatistics>(
    co2EmissionStatisticsQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !since || !until,
      variables: {
        assetFilters,
        period: {
          since: getUTCStartOfDayDate(since),
          until: getUTCEndOfDayDate(until),
        },
      },
    },
  );

  const parsedData = useMemo(() => {
    if (!data) return undefined;
    return parseGQLResponse(data.viewer?.co2Report.co2EmissionStatistics);
  }, [data]);

  return { loading, data: parsedData };
};

type Co2StatisticMetric = 'weight' | 'weightPer100Km' | 'weightPerHour';

export const Co2Statistics = ({
  assetFilters,
  since,
  until,
}: Co2EmissionsAnalysisCardProps) => {
  const { t } = useTranslation('analytics');
  const { loading, data } = useCo2EmissionStatistics({
    assetFilters,
    since,
    until,
  });
  const [selectedMetric, setSelectedMetric] = useState<{
    metric: Co2StatisticMetric;
    title: string;
  } | null>(null);

  const periodNbDays = moment(until).diff(moment(since), 'days');
  const currentPeriodLabel = `${formatDateShort(since)} - ${formatDateShort(
    until,
  )}`;
  const previousPeriodLabel = `${formatDateShort(
    moment(since).subtract(periodNbDays, 'days'),
  )} - ${formatDateShort(since)}`;

  const hasWeight = !!data?.co2Weight.current?.value;
  const hasWeightPer100Km = !!data?.co2WeightPer100Km.current?.value;
  const hasWeightPerHour = !!data?.co2WeightPerHour.current?.value;

  const drawerHeader = () => {
    if (!selectedMetric || !data) return null;

    const title = getPeriodLabel(since, until);
    let value: number | null = null;
    let comparativeValue: number | null = null;
    let unit: Unit = 't';
    switch (selectedMetric.metric) {
      case 'weight':
        value = data.co2Weight.current?.value ?? null;
        comparativeValue = data.co2Weight.previous?.value ?? null;
        if (data.co2Weight.current) unit = data.co2Weight.current.unit;
        break;
      case 'weightPer100Km':
        value = data.co2WeightPer100Km.current?.value ?? null;
        comparativeValue = data.co2WeightPer100Km.previous?.value ?? null;
        if (data.co2WeightPer100Km.current)
          unit = data.co2WeightPer100Km.current.unit;
        break;
      case 'weightPerHour':
        value = data.co2WeightPerHour.current?.value ?? null;
        comparativeValue = data.co2WeightPerHour.previous?.value ?? null;
        if (data.co2WeightPerHour.current)
          unit = data.co2WeightPerHour.current.unit;
        break;
    }
    return (
      <SimpleValueWithInfosCard
        title={title}
        infos={t('reports.common.assetsCountOverTotal', {
          count: data.currentAssetsCount,
          total: data.currentActiveAssetsCount,
        })}
        comparativeValue={comparativeValue}
        value={value}
        unit={unit}
      />
    );
  };

  const assetListPeriod = useMemo(() => {
    if (!data?.currentPeriod?.start || !data.currentPeriod.end) return null;
    return {
      start: new Date(data.currentPeriod.start),
      end: new Date(data.currentPeriod.end),
    };
  }, [data]);

  const { triggerAssetListExport, exportLoading } = useAssetListExport();
  const handleAssetListExport = useCallback(() => {
    if (!assetListPeriod) return;
    triggerAssetListExport(
      assetFilters,
      assetListPeriod.start,
      assetListPeriod.end,
      'daily',
    );
  }, [assetFilters, assetListPeriod, triggerAssetListExport]);

  const drawerContent = () => {
    if (!selectedMetric || !data?.currentPeriod) return null;

    return (
      <AssetsListTable
        assetFilters={assetFilters}
        assetListDataSource="daily"
        since={new Date(data.currentPeriod.start)}
        until={new Date(data.currentPeriod.end)}
        onExportClick={handleAssetListExport}
        exportLoading={exportLoading}
      />
    );
  };

  return (
    <Co2EmissionStatisticsRow>
      <Co2WeightStatisticCard
        title={t('reports.carbonEmissions.analysis.statistics.weight')}
        unit={data?.co2Weight.current?.unit || 't'}
        loading={loading}
        currentValue={data?.co2Weight.current?.value ?? null}
        previousValue={data?.co2Weight.previous?.value ?? null}
        currentAssetsCount={data?.currentAssetsCount}
        currentActiveAssetsCount={data?.currentActiveAssetsCount}
        previousAssetsCount={data?.previousAssetsCount}
        previousActiveAssetsCount={data?.previousActiveAssetsCount}
        currentPeriodLabel={currentPeriodLabel}
        previousPeriodLabel={previousPeriodLabel}
        onClick={
          hasWeight
            ? () => {
                setSelectedMetric({
                  metric: 'weight',
                  title: t(
                    'reports.carbonEmissions.analysis.statistics.weight',
                  ),
                });
              }
            : undefined
        }
      />
      <Co2WeightStatisticCard
        title={t(
          'reports.carbonEmissions.analysis.statistics.weightPerDistance',
        )}
        unit={data?.co2WeightPer100Km?.current?.unit || 'kg'}
        loading={loading}
        currentValue={data?.co2WeightPer100Km.current?.value ?? null}
        previousValue={data?.co2WeightPer100Km.previous?.value ?? null}
        currentAssetsCount={data?.currentAssetsCount}
        currentActiveAssetsCount={data?.currentActiveAssetsCount}
        previousAssetsCount={data?.previousAssetsCount}
        previousActiveAssetsCount={data?.previousActiveAssetsCount}
        currentPeriodLabel={currentPeriodLabel}
        previousPeriodLabel={previousPeriodLabel}
        onClick={
          hasWeightPer100Km
            ? () => {
                setSelectedMetric({
                  metric: 'weightPer100Km',
                  title: t(
                    'reports.carbonEmissions.analysis.statistics.weightPerDistance',
                  ),
                });
              }
            : undefined
        }
      />
      <Co2WeightStatisticCard
        title={t('reports.carbonEmissions.analysis.statistics.weightPerUsage')}
        unit={data?.co2WeightPerHour.current?.unit || 'kg'}
        loading={loading}
        currentValue={data?.co2WeightPerHour.current?.value ?? null}
        previousValue={data?.co2WeightPerHour.previous?.value ?? null}
        currentAssetsCount={data?.currentAssetsCount}
        currentActiveAssetsCount={data?.currentActiveAssetsCount}
        previousAssetsCount={data?.previousAssetsCount}
        previousActiveAssetsCount={data?.previousActiveAssetsCount}
        currentPeriodLabel={currentPeriodLabel}
        previousPeriodLabel={previousPeriodLabel}
        onClick={
          hasWeightPerHour
            ? () => {
                setSelectedMetric({
                  metric: 'weightPerHour',
                  title: t(
                    'reports.carbonEmissions.analysis.statistics.weightPerUsage',
                  ),
                });
              }
            : undefined
        }
      />
      <AssetListDrawer
        open={!!selectedMetric}
        title={selectedMetric?.title || t('reports.carbonEmissions.title')}
        header={drawerHeader()}
        mainContent={drawerContent()}
        onClose={() => {
          setSelectedMetric(null);
        }}
        trackingProps={{
          page: 'carbonEmissions',
          section: 'analysis',
          source: 'statisticsCard',
        }}
      />
    </Co2EmissionStatisticsRow>
  );
};
