import { useQuery } from '@apollo/client';
import type { TFunction } from 'i18next';
import type { AssetFilterGQL } from 'modules/analytics/components/AssetFilter/types';
import { AssetsTable } from 'modules/analytics/components/common/AssetListDrawer/AssetTable';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CellProps, Column } from 'react-table';
import {
  type AssetListDataSource,
  type Co2EmissionAssetsListItem,
  type Co2EmissionAssetsListResponse,
  usageHoursAssetsListQuery,
} from '../gql';
import { DataCell } from './DataCell';

type AssetsListTableProps = {
  assetFilters: AssetFilterGQL;
  assetListDataSource: AssetListDataSource;
  since: Date;
  until: Date;
  onExportClick?: () => void;
  exportLoading?: boolean;
};

const getMetricColumn = (t: TFunction): Column<Co2EmissionAssetsListItem> => {
  return {
    Header: t('reports.carbonEmissions.analysis.table.columns.totalCo2'),
    id: 'totalCo2Weight',
    Cell: ({ row: { original } }: CellProps<Co2EmissionAssetsListItem>) => {
      const { name, totalCo2Weight } = original;
      return (
        <DataCell
          value={totalCo2Weight?.value}
          unit={totalCo2Weight?.unit || 't'}
          title={name}
        />
      );
    },
    headerStyle: { paddingLeft: '8px' },
    style: { paddingLeft: '8px' },
    sortType: (rowA, rowB) => {
      if (!rowA.original.totalCo2Weight?.value) {
        return 1;
      }
      if (!rowB.original.totalCo2Weight?.value) {
        return -1;
      }
      return (
        rowA.original.totalCo2Weight?.value -
        rowB.original.totalCo2Weight?.value
      );
    },
  };
};

export const AssetsListTable = ({
  assetFilters,
  assetListDataSource,
  since,
  until,
  exportLoading,
  onExportClick,
}: AssetsListTableProps) => {
  const { t } = useTranslation('analytics');
  const [rows, setRows] = useState<Co2EmissionAssetsListItem[]>([]);

  const parseResponse = (data: Co2EmissionAssetsListResponse) => {
    setRows(data.viewer.co2Report.assetList);
  };

  const { loading } = useQuery<Co2EmissionAssetsListResponse>(
    usageHoursAssetsListQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !since || !until,
      onCompleted: parseResponse,
      variables: {
        assetFilters: assetFilters,
        period: {
          since: since,
          until: until,
        },
        dataSource: assetListDataSource,
      },
    },
  );

  const metricColumn = getMetricColumn(t);

  return (
    <AssetsTable
      since={since}
      until={until}
      metricColumn={metricColumn}
      loading={loading}
      page="carbonEmissions"
      rows={rows}
      exportLoading={exportLoading}
      onExportClick={onExportClick}
    />
  );
};
